import { IActionName } from '../../../../../store/redux/modal/modal.interface';

export interface IConfirmDeleteModalProps {
  closeModal?: () => void;
  modalPayload?: any;
  actionName?: IActionName;
}

export const getItemName = (action: IActionName | undefined) => {
  if (!action) return '';
  switch (action) {
    case 'PRODUCT':
      return 'категорию';
    case 'SUBPRODUCT':
      return 'услугу';
    case 'SALEPRODUCT':
      return 'продукт';
    case 'SALEPRODUCTS':
      return 'продукты';
    case 'SALEPRODUCT_CATEGORY':
      return 'категорию';
    case 'CHAT':
      return 'чат';
    case 'CLIENT':
      return 'клиента';
    case 'EMPLOYEE':
      return 'сотрудника';
    case 'CABINET':
      return 'ресурс';
    case 'PAYMENT':
      return 'способ оплаты';
    case 'CASH_REGISTER':
      return 'кассу';
    default:
      return '';
  }
};
