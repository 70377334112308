import axios from 'axios';

import { ILesson, ILessonBlock, ILessonUpdateNewDataOnBackend } from '../../../../store/redux/lessons/lessons.interface';
import { apiUpdateLessonDate } from '../../../../utils/api-constants';
import { apiDelete } from '../../../../utils/apiInstance';
import { apiGet, apiPost, apiPut } from '../../interceptors';

export const LessonsService = {
  async fetchLessons() {
    const response = await apiGet('/lessons');

    return response.data && response.data.lessons ? response.data.lessons : [];
  },
  async fetchLesson(lessonId: string | undefined) {
    if (!lessonId) return;
    const response = await apiGet(`/lesson/${lessonId}`);

    return response.data && response.data.lessons ? response.data.lessons : [];
  },
  async deleteLesson(lessonId: string) {
    return await apiDelete(`/lesson/${lessonId}`);
  },
  async createLesson(data: Omit<ILesson, 'ClientId'>) {
    return await apiPut(`/lesson`, data);
  },
  async closeLesson(lessonId: string) {
    return await apiPost(`/lesson/${lessonId}`, { stage: 4 });
  },
  async updateLessonComfirmation(data: Pick<ILesson, 'paymentFullComplete' | 'comfirmationCopmlete'>, lessonId: string) {
    return await apiPost(`/lesson/${lessonId}`, data);
  },
  async updateLesson(data: Omit<ILesson, 'ClientId'>, lessonId: string) {
    return await apiPost(`lesson/${lessonId}`, data);
  },
  async createBlockTime(data: ILessonBlock) {
    return await apiPost('/lessons/blocktime', data);
  },
  async updateBlockTime(data: ILessonBlock, lessonId: string) {
    return await apiPost(`/lesson/${lessonId}`, data);
  },
  async updateLessonsTimeOnBackend(data: ILessonUpdateNewDataOnBackend) {
    const response = await axios.put(`${apiUpdateLessonDate}`, data);

    return response.data;
  }
};
