import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { CategoryInput, CloseBtn, Wrapper } from './CreateSaleProductCategoryModal.styled';

import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { ModalSize, ModalType } from '../../../../../shared/ui';
import { ISaleproduct } from '../../../../../store/redux/saleproducts/saleproducts.interface';
import {
  useCreateSaleProductsCategory,
  useDeleteSaleProductsCategory,
  useUpdateSaleProductsCategory
} from '../../../../../store/redux/saleproductscategory/hooks/useSaleProductsCategoryMutation';
import { ISaleProductCategory } from '../../../../../store/redux/saleproductscategory/saleproductscategory.interface';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { validationCreateSaleProductCategorySchema } from '../../../../../utils/validation-input';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
  setIsCreateCategoryOpen: any;
}

const CreateSaleProductCategoryModal: React.FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { mutateAsync: createSaleProductsCategoryFn } = useCreateSaleProductsCategory();
  const { mutateAsync: deleteSaleProductsCategoryFn } = useDeleteSaleProductsCategory();
  const { mutateAsync: updateSaleProductsCategoryFn } = useUpdateSaleProductsCategory();
  const activeFilter = useTypedSelector((state) => state.saleproductscategory.activeFilter);
  const [pending, setPending] = useState(false);
  // const categories1 = useTypedSelector((state) => state.saleproductscategory.saleproductcategory);
  const saleproducts = modalPayload.saleproducts;
  const isEdit = modalPayload.isEdit;
  const { setActiveFilterCategory, openModal } = useActions();
  const catName = modalPayload.categories?.find((cat: ISaleProductCategory) => cat.id == activeFilter);
  const findProductsByCategory = () => isEdit && saleproducts && saleproducts?.filter((prod: ISaleproduct) => prod.category == catName?.id);

  const initialValues = {
    name: ''
  };

  const formik = useFormik({
    initialValues: isEdit ? { name: catName && catName.name } : initialValues,
    validationSchema: validationCreateSaleProductCategorySchema,
    onSubmit: () => {},
    validateOnBlur: true
  });

  const handleDeleteCatergory = async (id: string) => {
    if (findProductsByCategory().length > 0) {
      toast.error('Вы не можете удалить категорию пока в ней есть товары');
    } else {
      openModal({
        modalName: 'CONFIRM_NEW_DELETE',
        modalSize: ModalSize.CONFIRM_DELETE,
        modalType: ModalType.TOP,
        modalPayload: { setActiveFilterCategory, id },
        actionName: 'SALEPRODUCT_CATEGORY',
        classModal: 'danger'
      });
      closeModal?.();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { name } = formik.values;

    if (!name) {
      toast.error('Название категории обязательно');

      return;
    }

    if (isEdit) {
      setPending(true);
      const res = await updateSaleProductsCategoryFn({
        name,
        id: activeFilter
      });
      if (res?.status === 'ok') {
        await setActiveFilterCategory(activeFilter);
        closeModal!();
        toast.success('Название категории успешно изменено');
        setPending(false);
      } else {
        toast.error('Произошла ошибка изменения категории');
        setPending(false);
      }
    } else {
      setPending(true);
      const res = await createSaleProductsCategoryFn({
        name
      });

      if (res?.status === 'ok') {
        closeModal!();
        toast.success('Категория успешно добавлена');
        setPending(false);
      } else {
        toast.error('Произошла ошибка добавления категории');
        setPending(false);
      }
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <FlexWithAlign
        className='form-wrapper'
        $column
      >
        <FlexWithAlign
          className='top-wrapper'
          $gap={'12px'}
          $align={'center'}
        >
          <CloseBtn
            className='flex closebtn'
            onClick={closeModal}
          >
            <IconNew name={EIcon.blackclose} />
          </CloseBtn>
          <NewText
            $tag={TextType.H1}
            fontWeight={600}
            className='title'
          >
            {isEdit ? 'Редактирование' : 'Добавление категории'}
          </NewText>
        </FlexWithAlign>
        <CategoryInput>
          <NewInput
            label='Название категории'
            type='text'
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          />
          <FlexWithAlign
            $gap='16px'
            $justify={'space-between'}
            className={'full'}
          >
            {isEdit && (
              <NewButton
                typeBtn='only-icon'
                onClick={() => handleDeleteCatergory(activeFilter)}
                className='pencil-icon'
              >
                <IconNew name={EIcon.trashred} />
              </NewButton>
            )}
            <FlexWithAlign
              $gap='16px'
              $justify={'flex-end'}
              className={'full'}
            >
              <NewButton
                className={'reset-btn'}
                onClick={closeModal}
              >
                Отменить
              </NewButton>
              <NewButton
                className={'create-btn'}
                typeBtn={'black'}
                type={'submit'}
                disabled={pending}
              >
                Сохранить
              </NewButton>
            </FlexWithAlign>
          </FlexWithAlign>
        </CategoryInput>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default CreateSaleProductCategoryModal;
