import styled from 'styled-components';

import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    color: #151618;
  }
  .top-wrapper {
    padding: 0 6.6px;
    width: 100%;
    gap: 22px;
  }
  &.top {
    margin-top: 0;
  }
  .form-wrapper {
    gap: 10px;
  }
  .names-container {
    padding-top: 14px;
  }
`;

export const UserItemContainer = styled(FlexWithAlign)`
  padding-block: 17px;
  border-bottom: 1px solid ${(props) => props.theme.color.base10};
  gap: 12px
`;

export const UsetItemTextContainer = styled.div`
  width: 100%;
`;

export const UserCardWrapper = styled(FlexWithAlign)`
  max-height: 20rem;
  overflow-y: auto;
`;

export const CloseBtn = styled.div`
  padding-top: 9px;
  cursor: pointer;
`;
