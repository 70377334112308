import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { CloseBtn, Wrapper, UserCardWrapper, UserItemContainer, UsetItemTextContainer } from './UpdateChatModal.styled';

import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewCustomCheckbox from '../../../../../shared/new-ui/NewCustomCheckbox/ui/NewCustomCheckbox';
import { NewInput } from '../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { ModalSize, ModalType, TextType } from '../../../../../shared/ui';
import { useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { sendMessage } from '../../../../../store/redux/websocket/chat-reducer';
import { MODAL_GAP } from '../../../../../styles';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { validationCreateChat } from '../../../../../utils/validation-input';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const UpdateChatModal: React.FC<IProps> = (props) => {
  const { closeModal } = props;
  const { roomId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userChats } = useTypedSelector((state) => state.chat);
  const findChat = userChats.find((item) => item.chat_id === roomId);
  const { data: users } = useUsersQuery();
  const { openModal } = useActions();
  const ref = useRef();
  const usersInChat = users?.filter((e) => findChat?.users.findIndex((i) => i.user_id == e.id) !== -1);

  const initialValues = {
    chatName: findChat?.chat_name ? findChat?.chat_name : ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
    validationSchema: validationCreateChat,
    validateOnBlur: true
  });

  const sendDeleteChat = (chat_id: string | undefined) => {
    openModal({
      modalName: 'CONFIRM_NEW_DELETE',
      modalSize: ModalSize.CONFIRM_DELETE,
      modalType: ModalType.TOP,
      modalPayload: chat_id,
      actionName: 'CHAT',
      classModal: 'danger'
    });
  };

  const sendRenameChat = () => {
    dispatch(
      sendMessage(JSON.stringify({ type: 'rename_chat', data: { chat_id: findChat?.chat_id, new_name: formik.values.chatName } })) as any
    );
    closeModal();
  };

  return (
    <Wrapper>
      <FlexWithAlign
        className='form-wrapper'
        $column
      >
        <FlexWithAlign
          className='top-wrapper'
          $gap={'12px'}
          $align={'center'}
        >
          <CloseBtn onClick={closeModal}>
            <IconNew name={EIcon.blackclose} />
          </CloseBtn>
          <NewText
            $tag={TextType.H1}
            fontWeight={600}
            className='title'
          >
            {t('Редактирование чата')}
          </NewText>
        </FlexWithAlign>
        <FlexWithAlign
          $column
          $gap={MODAL_GAP}
        >
          <UserCardWrapper
            $column
            $gap={'0px'}
          >
            <NewInput
              ref={ref}
              name={'chatName'}
              value={formik.values.chatName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.chatName && formik.errors.chatName}
            />
            <div className='names-container'>
              {usersInChat?.map((user) => (
                <UserItemContainer key={user.id}>
                  <NewCustomCheckbox
                    name={user.id}
                    value={true}
                    disabled={true}
                    onChange={() => {}}
                  />
                  <UsetItemTextContainer>
                    <FlexWithAlign
                      className={'messenger-modal'}
                      $position={'flex'}
                      $column={false}
                      $justify={'space-between'}
                    >
                      <NewText
                        $customSize={TextCustomType.T14}
                        $tag={TextType.P}
                        fontWeight={400}
                        color={'title'}
                      >
                        {user.fio}
                      </NewText>

                      <NewText
                        $customSize={TextCustomType.T14}
                        $tag={TextType.P}
                        fontWeight={400}
                        color={'grey'}
                      >
                        {user.Email}
                      </NewText>
                    </FlexWithAlign>
                  </UsetItemTextContainer>
                </UserItemContainer>
              ))}
            </div>
          </UserCardWrapper>
          <FlexWithAlign
            $gap={MODAL_GAP}
            $align={'center'}
            $justify={'space-between'}
          >
            <NewButton
              className={'messenger-edit-button-trash'}
              typeBtn='only-icon'
              onClick={() => {
                sendDeleteChat(roomId);
              }}
            >
              <IconNew name={EIcon.trashred} />
            </NewButton>
            <NewButton
              typeBtn={'black'}
              onClick={sendRenameChat}
              disabled={formik.values.chatName === ''}
            >
              {t('Сохранить').toUpperCase()}
            </NewButton>
          </FlexWithAlign>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default UpdateChatModal;
